<template>
  <div class="background">
    <Roles />
  </div>
</template>

<script>
import Roles from "@/components/elements/dashboard/roles/Roles";

export default {
  name: "DocumentManagementRoles",

  components: {
    Roles,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
