<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="roles"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="rolesPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Roles</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('role_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Role
                  </v-btn>
                </template>
                <v-card :class="customClass">
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- Role Name -->
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedRole.name"
                              :rules="nameRules"
                              label="Role name"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Role Name <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Permissions -->
                          <v-col class="mt-n10" cols="12">
                            <v-select
                              v-model="permissionNames"
                              :items="permissionNamesList"
                              label="Permissions"
                              multiple
                              chips
                            >
                              <template v-slot:label>
                                <p class="body-1">Permissions</p>
                              </template>

                              <template v-slot:prepend-item>
                                <v-list-item
                                  ripple
                                  @mousedown.prevent
                                  @click="toggle"
                                >
                                  <v-list-item-action>
                                    <v-icon
                                      :color="
                                        permissionNames.length > 0
                                          ? 'indigo darken-4'
                                          : ''
                                      "
                                    >
                                      {{ icon }}
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Select All
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                            </v-select>
                          </v-col>

                          <!-- Companies -->
                          <v-col
                            v-if="$can('super_admin')"
                            class="mt-n5"
                            cols="12"
                          >
                            <v-select
                              v-model="companyName"
                              :items="companyNamesList"
                              :rules="companyNameRules"
                              attach
                              label="Companies"
                            >
                              <template v-slot:label>
                                <p class="body-1">Company</p>
                              </template>
                            </v-select>
                          </v-col>

                          <!-- Is Super Admin -->
                          <v-col
                            v-if="$can('super_admin')"
                            class="mt-n10"
                            cols="12"
                          >
                            <v-checkbox
                              v-model="editedRole.is_super_admin"
                              label="Is Super Admin"
                            >
                              <!-- <template v-slot:label>
                                <p class="body-1">Is Super Admin</p>
                              </template> -->
                            </v-checkbox>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this role?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="btn btn-primary" @click="deleteRoleConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- is_super_admin -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.is_super_admin="{ item }">
            <v-chip
              v-if="item.is_super_admin"
              class="ma-2"
              color="green"
              text-color="white"
            >
              Yes
            </v-chip>

            <v-chip v-else class="ma-2" color="red" text-color="white">
              No
            </v-chip>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('role_update')"
              small
              class="mr-2"
              @click="editRole(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('role_delete')"
              small
              @click="deleteRole(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Roles per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementRoles",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Is Super Admin",
          value: "is_super_admin",
        },
        { text: "Company Id", value: "company_id" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      roles: [],
      permissions: [],
      permissionNames: [],
      permissionNamesList: [],
      companies: [],
      companyName: "",
      companyNamesList: [],
      editedIndex: -1,
      editedRole: {
        id: 0,
        name: "",
        company_id: 0,
        permissions: [],
        is_super_admin: false,
        created_at: "",
        updated_at: "",
      },
      defaultRole: {
        id: 0,
        name: "",
        company_id: 0,
        permissions: [],
        is_super_admin: false,
        created_at: "",
        updated_at: "",
      },

      nameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // companyName
      companyNameRules: [],

      page: 1,
      rolesPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],

      customClass: "",
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1 ? "New Role" : "Edit Role";
    },

    // selectAllPermissions
    selectAllPermissions() {
      return this.permissionNames.length === this.permissionNamesList.length;
    },

    // selectSomePermissions
    selectSomePermissions() {
      return this.permissionNames.length > 0 && !this.selectAllPermissions;
    },

    // icon
    icon() {
      if (this.selectAllPermissions) return "mdi-close-box";
      if (this.selectSomePermissions) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedRoles();
    },

    // rolesPerPage
    rolesPerPage() {
      this.getApiPagedRoles();
    },

    // perPageChoice
    perPageChoice(val) {
      this.rolesPerPage = val.value;
      this.page = 1;
    },
  },

  created() {
    if (!this.$can("role_access")) {
      this.$router.push("/dashboard/inbox");
    }

    if (!this.$can("super_admin")) {
      this.headers = [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    }

    if (this.$can("super_admin")) {
      this.getApiCompanies();
    }

    // customClass
    this.customClass = "padding-bottom";

    // this.getApiRoles();
    this.getApiPagedRoles();

    this.getApiPermissions();
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // toggle
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllPermissions) {
          this.permissionNames = [];
        } else {
          this.permissionNames = this.permissionNamesList.slice();
        }
      });
    },

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiRole();
        } else {
          this.createApiRole();
        }
      }
    },

    // editRole
    editRole(role) {
      this.editedIndex = this.roles.indexOf(role);
      this.editedRole = Object.assign({}, role);
      this.dialog = true;

      if (this.editedIndex != -1) {
        this.companyName = this.$helpers.getNameByIdFromList(
          this.companies,
          role.company_id
        );

        // this.editedRole.permissions = [1, 2, 3, 4, 5];

        this.permissionNames = this.$helpers.getNamesByIdsFromList(
          this.permissions,
          this.editedRole.permissions
        );
      }

      if (this.editedIndex !== -1) {
        this.$refs.form.validate();
      }
    },

    // deleteRole
    deleteRole(role) {
      this.editedIndex = this.roles.indexOf(role);
      this.editedRole = Object.assign({}, role);
      this.dialogDelete = true;
    },

    // deleteRoleConfirm
    deleteRoleConfirm() {
      this.deleteApiRole();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedRole = Object.assign({}, this.defaultRole);
        this.editedIndex = -1;
        this.permissionNames = [];
        this.companyName = "";
      });

      this.$refs.form.reset();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedRole = Object.assign({}, this.defaultRole);
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // Get Api Roles
    async getApiRoles() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("roles");

      if (res.status == 200) {
        this.roles = res.data;
      }

      this.overlay = false;
    },

    // Get Api Paged Roles
    async getApiPagedRoles() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `roles/paged?page_no=${this.page}&page_size=${this.rolesPerPage}`
      );

      if (res.status == 200) {
        this.roles = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // Create Api Role
    async createApiRole() {
      this.overlay = true;

      var reqData = {
        name: this.editedRole.name,
        permissions: this.$helpers.getIdsByNamesFromList(
          this.permissions,
          this.permissionNames
        ),
        is_super_admin: this.editedRole.is_super_admin,
        company_id: this.auth.user.company_id,
      };

      if (this.companyName != "") {
        var id = this.$helpers.getIdByNameFromList(
          this.companies,
          this.companyName
        );

        if (id > 0) {
          reqData.company_id = id;
        }
      }

      const res = await this.$helpers.createApiData(
        "roles",
        reqData,
        "Role Created Successfully!"
      );

      if (res.status == 200) {
        this.roles.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Update Api Role
    async updateApiRole() {
      this.overlay = true;

      var reqData = {
        id: this.editedRole.id,
        name: this.editedRole.name,
        permissions: this.$helpers.getIdsByNamesFromList(
          this.permissions,
          this.permissionNames
        ),
        is_super_admin: this.editedRole.is_super_admin,
        company_id: this.auth.user.company_id,
      };

      if (this.companyName != "") {
        var id = this.$helpers.getIdByNameFromList(
          this.companies,
          this.companyName
        );

        if (id > 0) {
          reqData.company_id = id;
        }
      }

      const res = await this.$helpers.updateApiData(
        `roles/${this.editedRole.id}`,
        reqData,
        "Role Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.roles[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Delete Api Role
    async deleteApiRole() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `roles/${this.editedRole.id}`,
        "Role Deleted Successfully!"
      );

      if (res.status == 200) {
        this.roles.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },

    // Get Api Permissions
    async getApiPermissions() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("permissions");

      if (res.status == 200) {
        this.permissions = res.data;

        this.permissionNamesList = this.$helpers.getNamesFromList(
          this.permissions
        );
      }

      this.overlay = false;
    },

    // Get Api Companies
    async getApiCompanies() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("companies");

      if (res.status == 200) {
        this.companies = res.data;

        this.companyNamesList = this.$helpers.getNamesFromList(this.companies);
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.padding-bottom {
  padding-bottom: 120px;
}
</style>
